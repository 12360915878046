import React from "react";
import { Global, css } from "@emotion/react";
import FooterMobile from "../Footer/index";

const MobileLayout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            border-radius: 4px;
          }
          ::-webkit-scrollbar-track {
            border-radius: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: rgba(14, 19, 24, 0.4);
            border-radius: 4px;
          }
          body {
            overflow-x: hidden;
            overflow-y: overlay;
            margin: 0;
          }
          html {
            font-family: "Nunito Sans", "prompt", sans-serif;
            scrollbar-width: thin;
            scroll-behavior: smooth;
          }
        `}
      />
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            backgroundColor: "#fff",
            zIndex: 4,
          }}
        >
          <div style={{ flexGrow: 1, overflow: "hidden" }}>{children}</div>
        </div>
        {/* <FooterMobile /> */}
      </div>
    </>
  );
};

export default MobileLayout;
